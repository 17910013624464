@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.App {
  font-family: "Poppins", sans-serif;
  z-index: 10;
  position: relative;
}
button,
input {
  font-family: "Poppins", sans-serif;
}
#bg-wrap {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  /* display: none; */
}

#bg-wrap svg {
  opacity: 0.3;
}
