.step_wrapper {
  width: 100%;
  min-height: 30vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  padding-bottom: 10vh;
  .step_container {
    width: calc(100% - 10vw);
    min-height: 10vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #f2411f;
    border-radius: 1.8229166666666667vw;
    padding: 6vw;
    .step_headline {
      font-size: 1.8229166666666667vw;
      line-height: 2.8229166666666667vw;
      font-weight: 600;
      width: 60%;
      margin-bottom: 5.208333333333333vw;
    }
    .step_box_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .box {
        width: 18.229166666666668vw;
        min-height: 20vh;
        height: auto;
        border: 0.052083333333333336vw solid #ffffff63;
        border-radius: 1.0416666666666667vw;
        backdrop-filter: blur(1.0416666666666667vw);
        background: linear-gradient(#ffffff13, rgba(255, 255, 255, 0.144));
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0 2vw 2.6041666666666665vw;
        text-align: center;

        .img_circle {
          height: 6.770833333333333vw;
          width: 6.770833333333333vw;
          border-radius: 5.208333333333333vw;
          position: absolute;
          top: -3.3854166666666665vw;

          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .box_headline {
          margin: 4.6875vw 0 2.0833333333333335vw 0;
          font-size: 1.8458333333333333vw;
          line-height: 1.9458333333333333vw;
          font-weight: 600;
          height: 3.6458333333333335vw;
        }
        .stars {
          width: 100%;
          padding: 20%;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .step_wrapper {
    .step_container {
      width: calc(100% - 10vw);
      min-height: 10vh;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      flex-direction: column;
      background: #f2411f;
      border-radius: 1.8229166666666667vw;
      padding: 6vw;
      .step_headline {
        font-size: 7vw;
        line-height: 8vw;
        width: 100%;
        margin-bottom: 15.208333333333333vw;
        text-align: center;
      }
      .step_box_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        .box {
          width: 100%;
          margin-bottom: 15vw;

          .img_circle {
            height: 20vw;
            width: 20vw;
            border-radius: 10vw;
            top: -10vw;
          }
          .box_headline {
            margin: 15.6875vw 0 2.0833333333333335vw 0;
            font-size: 5.8458333333333333vw;
            line-height: 5.9458333333333333vw;
            font-weight: 600;
            height: 3.6458333333333335vw;
          }
          .stars {
            width: 80%;
            padding: 20%;
          }
        }
      }
    }
  }
}
