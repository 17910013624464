.banner_wrapper {
  width: 100%;
  min-height: 50vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 7.5vh 0;
  border-bottom: 1px solid #ffffff0c;
  .banner_container {
    width: calc(100% - 10vw);
    min-height: 20vh;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5vh;

    .banner_info_left {
      min-height: 20vh;
      height: auto;
      width: 50%;

      .descripiton_info {
        border-radius: 2.6041666666666665vw;
        height: 2.0833333333333335vw;
        border: 1px solid #fff;
        letter-spacing: 0.5208333333333334vw;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 0 2vw;
        font-size: 0.8333333333333334vw;
      }
      .banner_headline {
        font-size: 4.166666666666667vw;
        line-height: 4.166666666666667vw;
        margin: 2.0833333333333335vw 0;
        font-weight: 500;
        span {
          color: #f2411f;
          font-weight: 700;
          &:nth-child(2) {
            color: #fff;
            position: relative;
            img {
              position: absolute;
              bottom: 0;
              left: -30%;
              transform: translateX(50%);
              z-index: -1;
              width: 80%;
            }
          }
        }
      }
      .banner_subheadline {
        font-size: 1.0416666666666667vw;
        font-weight: 200;
        margin-bottom: 2.0833333333333335vw;
      }
    }
    .banner_info_right {
      min-height: 20vh;
      height: auto;
      width: 50%;
      img {
        width: 100%;
        padding: 5%;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .banner_wrapper {
    .banner_container {
      flex-direction: column-reverse;
      padding-top: 0;
      .banner_info_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .descripiton_info {
          border-radius: 5.6041666666666665vw;
          height: 8.0833333333333335vw;
          padding: 0 6vw;
          font-size: 2.8333333333333334vw;
        }
        .banner_headline {
          font-size: 8.166666666666667vw;
          line-height: 8.166666666666667vw;
          margin: 2.0833333333333335vw 0;
          text-align: center;
        }
        .banner_subheadline {
          font-size: 5.0416666666666667vw;
          text-align: center;
        }
      }
      .banner_info_right {
        width: 100%;
      }
    }
  }
}

// 768px

// 992px
