.whataspp_container {
  width: 4vw;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2411f;
  position: fixed;
  bottom: 2vw;
  right: 5vw;
  border-radius: 3.125vw;
  border: 1px solid #ffffff2c;
  z-index: 9980;
  img {
    width: 2vw;
  }
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background: #af3219;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .whataspp_container {
    width: 10vw;
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2411f;
    position: fixed;
    bottom: 6vw;
    right: 6vw;
    border-radius: 10vw;

    img {
      width: 5vw;
    }
  }
}
