.button_default {
  height: 3.6458333333333335vw;
  width: fit-content;
  padding: 0 2vw;
  background: #f2411f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15625vw;
  border-radius: 3.6458333333333335vw;
  border: none;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background: #af3219;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .button_default {
    padding: 0 7vw;
    height: 8.6458333333333335vw;
    border-radius: 8vw;
    border: none;
    font-weight: 600;
    font-size: 2.5vw;
  }
}
