.products_wrapper {
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  .products_container {
    width: calc(100% - 10vw);
    min-height: 50vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .products_headline {
      font-size: 2.34375vw;
      text-transform: uppercase;
      margin-bottom: 7.8125vw;
      font-weight: 700;
    }

    .products_box_container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: start;
      flex-wrap: wrap;
      .box {
        width: 23%;
        min-height: 35vh;
        height: auto;
        border-left: 1px solid #ffffff56;
        border-radius: 1.0416666666666667vw;
        backdrop-filter: blur(1.0416666666666667vw);
        background: linear-gradient(#ffffff13, rgba(255, 255, 255, 0.144));
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0 2vw 2.6041666666666665vw !important;
        text-align: center;
        margin-bottom: 8vh !important;
        transition: all ease 0.3s;
        margin-right: 2% !important;
        &:nth-child(4) {
          margin-right: 0;
        }

        .img_circle {
          height: 6.770833333333333vw;
          width: 6.770833333333333vw;
          border-radius: 5.208333333333333vw;
          position: absolute;
          top: -3.3854166666666665vw;
          transform: translateX(-50%);
          left: 50%;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          color: #f2411f;
          font-weight: 700;
          font-size: 1.1458333333333333vw;
          img {
            width: 50%;
          }
        }
        .box_headline {
          margin: 4.6875vw 0 0.5208333333333334vw 0;
          font-size: 1.1458333333333333vw;
          font-weight: 600;
          height: 1.5625vw;
        }
        p {
          font-size: 0.9333333333333334vw;
          font-weight: 300;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .products_wrapper {
    .products_container {
      .products_headline {
        font-size: 10vw;
        line-height: 5vh;
        text-transform: uppercase;
        margin-bottom: 20vw;
        font-weight: 700;
        text-align: center;
      }

      .products_box_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        .box {
          width: 100%;
          min-height: 30vh;
          margin-bottom: 8vh;
          border-radius: 3vw;

          .img_circle {
            height: 20vw;
            width: 20vw;
            border-radius: 10vw;
            position: absolute;
            top: -10vw;
            font-weight: 700;
            font-size: 4vw;
          }
          .box_headline {
            margin: 15vw 0 10vw 0;
            font-size: 7vw;
            font-weight: 600;
            height: 1.5625vw;
          }
          p {
            font-size: 3.9333333333333334vw;
            font-weight: 300;
          }
        }
      }
    }
  }
}
