.whataspp_container {
  width: 4vw;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2411f;
  position: fixed;
  bottom: 2vw;
  right: 5vw;
  border-radius: 3.125vw;
  border: 1px solid rgba(255, 255, 255, 0.1725490196);
  z-index: 9980;
  cursor: pointer;
  transition: all ease 0.2s;
}
.whataspp_container img {
  width: 2vw;
}
.whataspp_container:hover {
  background: #af3219;
}

@media (min-width: 0px) and (max-width: 768px) {
  .whataspp_container {
    width: 10vw;
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2411f;
    position: fixed;
    bottom: 6vw;
    right: 6vw;
    border-radius: 10vw;
  }
  .whataspp_container img {
    width: 5vw;
  }
}/*# sourceMappingURL=style.css.map */