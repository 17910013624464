.step_wrapper {
  width: 100%;
  min-height: 30vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  padding-bottom: 10vh;
}
.step_wrapper .step_container {
  width: calc(100% - 10vw);
  min-height: 10vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #f2411f;
  border-radius: 1.8229166667vw;
  padding: 6vw;
}
.step_wrapper .step_container .step_headline {
  font-size: 1.8229166667vw;
  line-height: 2.8229166667vw;
  font-weight: 600;
  width: 60%;
  margin-bottom: 5.2083333333vw;
}
.step_wrapper .step_container .step_box_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.step_wrapper .step_container .step_box_container .box {
  width: 18.2291666667vw;
  min-height: 20vh;
  height: auto;
  border: 0.0520833333vw solid rgba(255, 255, 255, 0.3882352941);
  border-radius: 1.0416666667vw;
  -webkit-backdrop-filter: blur(1.0416666667vw);
          backdrop-filter: blur(1.0416666667vw);
  background: linear-gradient(rgba(255, 255, 255, 0.0745098039), rgba(255, 255, 255, 0.144));
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 2vw 2.6041666667vw;
  text-align: center;
}
.step_wrapper .step_container .step_box_container .box .img_circle {
  height: 6.7708333333vw;
  width: 6.7708333333vw;
  border-radius: 5.2083333333vw;
  position: absolute;
  top: -3.3854166667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step_wrapper .step_container .step_box_container .box .img_circle img {
  width: 100%;
  height: 100%;
}
.step_wrapper .step_container .step_box_container .box .box_headline {
  margin: 4.6875vw 0 2.0833333333vw 0;
  font-size: 1.8458333333vw;
  line-height: 1.9458333333vw;
  font-weight: 600;
  height: 3.6458333333vw;
}
.step_wrapper .step_container .step_box_container .box .stars {
  width: 100%;
  padding: 20%;
}

@media (min-width: 0px) and (max-width: 768px) {
  .step_wrapper .step_container {
    width: calc(100% - 10vw);
    min-height: 10vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #f2411f;
    border-radius: 1.8229166667vw;
    padding: 6vw;
  }
  .step_wrapper .step_container .step_headline {
    font-size: 7vw;
    line-height: 8vw;
    width: 100%;
    margin-bottom: 15.2083333333vw;
    text-align: center;
  }
  .step_wrapper .step_container .step_box_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .step_wrapper .step_container .step_box_container .box {
    width: 100%;
    margin-bottom: 15vw;
  }
  .step_wrapper .step_container .step_box_container .box .img_circle {
    height: 20vw;
    width: 20vw;
    border-radius: 10vw;
    top: -10vw;
  }
  .step_wrapper .step_container .step_box_container .box .box_headline {
    margin: 15.6875vw 0 2.0833333333vw 0;
    font-size: 5.8458333333vw;
    line-height: 5.9458333333vw;
    font-weight: 600;
    height: 3.6458333333vw;
  }
  .step_wrapper .step_container .step_box_container .box .stars {
    width: 80%;
    padding: 20%;
  }
}/*# sourceMappingURL=style.css.map */