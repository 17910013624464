.banner_wrapper {
  width: 100%;
  min-height: 50vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 7.5vh 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.0470588235);
}
.banner_wrapper .banner_container {
  width: calc(100% - 10vw);
  min-height: 20vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
}
.banner_wrapper .banner_container .banner_info_left {
  min-height: 20vh;
  height: auto;
  width: 50%;
}
.banner_wrapper .banner_container .banner_info_left .descripiton_info {
  border-radius: 2.6041666667vw;
  height: 2.0833333333vw;
  border: 1px solid #fff;
  letter-spacing: 0.5208333333vw;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 2vw;
  font-size: 0.8333333333vw;
}
.banner_wrapper .banner_container .banner_info_left .banner_headline {
  font-size: 4.1666666667vw;
  line-height: 4.1666666667vw;
  margin: 2.0833333333vw 0;
  font-weight: 500;
}
.banner_wrapper .banner_container .banner_info_left .banner_headline span {
  color: #f2411f;
  font-weight: 700;
}
.banner_wrapper .banner_container .banner_info_left .banner_headline span:nth-child(2) {
  color: #fff;
  position: relative;
}
.banner_wrapper .banner_container .banner_info_left .banner_headline span:nth-child(2) img {
  position: absolute;
  bottom: 0;
  left: -30%;
  transform: translateX(50%);
  z-index: -1;
  width: 80%;
}
.banner_wrapper .banner_container .banner_info_left .banner_subheadline {
  font-size: 1.0416666667vw;
  font-weight: 200;
  margin-bottom: 2.0833333333vw;
}
.banner_wrapper .banner_container .banner_info_right {
  min-height: 20vh;
  height: auto;
  width: 50%;
}
.banner_wrapper .banner_container .banner_info_right img {
  width: 100%;
  padding: 5%;
}

@media (min-width: 0px) and (max-width: 768px) {
  .banner_wrapper .banner_container {
    flex-direction: column-reverse;
    padding-top: 0;
  }
  .banner_wrapper .banner_container .banner_info_left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .banner_wrapper .banner_container .banner_info_left .descripiton_info {
    border-radius: 5.6041666667vw;
    height: 8.0833333333vw;
    padding: 0 6vw;
    font-size: 2.8333333333vw;
  }
  .banner_wrapper .banner_container .banner_info_left .banner_headline {
    font-size: 8.1666666667vw;
    line-height: 8.1666666667vw;
    margin: 2.0833333333vw 0;
    text-align: center;
  }
  .banner_wrapper .banner_container .banner_info_left .banner_subheadline {
    font-size: 5.0416666667vw;
    text-align: center;
  }
  .banner_wrapper .banner_container .banner_info_right {
    width: 100%;
  }
}/*# sourceMappingURL=style.css.map */