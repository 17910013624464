.menu_wrapper {
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}
.menu_wrapper .menu_container {
  width: calc(100% - 10vw);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu_wrapper .menu_container .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu_wrapper .menu_container .logo img {
  position: relative;
  top: -1vh;
  width: 180px;
}
.menu_wrapper .menu_container .logo .nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2.2083333333vw;
  height: 100%;
}
.menu_wrapper .menu_container .logo .nav div {
  font-size: 0.625vw;
  text-transform: uppercase;
  letter-spacing: 0.15625vw;
  margin-right: 1.5vw;
  color: #fff;
  transition: all ease 0.2s;
  cursor: pointer;
}
.menu_wrapper .menu_container .logo .nav div:hover {
  color: #f2411f;
}
.menu_wrapper .menu_container .logo .nav .navop {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}
.menu_wrapper .menu_container button {
  height: 2.6041666667vw;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 2vw;
  background: #f2411f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15625vw;
  border-radius: 2.6041666667vw;
  border: none;
  font-weight: 600;
  font-size: 0.7291666667vw;
  cursor: pointer;
  transition: all ease 0.2s;
}
.menu_wrapper .menu_container button:hover {
  background: #af3219;
}

.active {
  background: #222330;
  height: 70px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .menu_wrapper {
    width: 100%;
    height: 18vw;
  }
  .menu_wrapper .menu_container .logo img {
    width: 100px;
  }
  .menu_wrapper .menu_container .logo .nav div {
    font-size: 2vw;
  }
  .menu_wrapper .menu_container button {
    padding: 0 2vw;
    height: 5.6458333333vw;
    font-size: 2vw;
  }
  .active {
    background: #222330;
    height: 10vw;
  }
}/*# sourceMappingURL=style.css.map */