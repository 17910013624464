.footer_wrapper {
  width: 100%;
  min-height: 30vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #f2411f;
  // padding-top: 10vh;
  border-radius: 1.5625vw 1.5625vw 0 0;

  .Marquee {
    border-bottom: 1px solid #22233033;
    width: 100%;
    height: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    font-weight: 200;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
  }
  .Marquee-content {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-animation: marquee 40s linear infinite running;
    -moz-animation: marquee 40s linear infinite running;
    -o-animation: marquee 40s linear infinite running;
    -ms-animation: marquee 40s linear infinite running;
    animation: marquee 40s linear infinite running;
  }
  .Marquee-content:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
    animation-play-state: paused;
  }
  .Marquee-tag {
    width: fit-content;
    white-space: nowrap;
    margin: 0 50px;
    padding: 0.5em;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    .info_mar {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #222330;
      font-weight: 600;
      margin-right: 50px;
      width: 50%;
      font-size: 1.4vw;
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        color: #222330;
        cursor: pointer;
        button {
          width: fit-content;
          padding: 0 2vw;
          height: 5vh;
          background: #222330;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 0.15625vw;
          border-radius: 3.6458333333333335vw;
          border: none;
          font-weight: 600;
          font-size: 0.7291666666666666vw;
          margin-right: 20px;
        }
      }
    }
  }
  .Marquee-tag:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
  }
  @-moz-keyframes marquee {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -o-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
    }
  }
  @-webkit-keyframes marquee {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -o-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
    }
  }
  @-o-keyframes marquee {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -o-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
    }
  }
  @keyframes marquee {
    0% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -o-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
    }
  }

  .footer_container {
    width: calc(100% - 4vw);
    min-height: 50vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #fff;
    border-radius: 1.5625vw 1.5625vw 0 0;
    padding: 4vw;

    .footer_left {
      width: 30%;
      min-height: 30vh;
      height: auto;
      .footer_headline {
        font-size: 1.1458333333333333vw;
        width: 80%;
        margin-top: 1.3020833333333333vw;
      }

      form {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-top: 1.5625vw;
        input {
          height: 2.6041666666666665vw;
          margin-bottom: 1.0416666666666667vw;
          border-radius: 0.5208333333333334vw;
          width: 82%;
          background: #dfdfdf;
          padding: 1.0416666666666667vw;
          border: 1px solid #c3c3c3;
        }

        .check_container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 60%;
          height: 1.0416666666666667vw;
          margin-bottom: 1.5625vw;
          input {
            height: 1.0416666666666667vw;
            margin-bottom: 0;
            border-radius: 0;
            width: auto;
            background: #dfdfdf;
            padding: 0 !important;
            border: 1px solid #c3c3c3;
            margin-right: 0.5208333333333334vw;
          }
          font-size: 12px;

          span {
            color: #f2411f;
            margin-left: 0.2604166666666667vw;
            font-weight: 600;
          }
        }
        .form__sub {
          width: 83%;
          font-size: 10px;
          input[type="submit"] {
            height: 3.6458333333333335vw;
            width: fit-content;
            padding: 0 2vw;
            background: #f2411f;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 0.15625vw;
            border-radius: 3.6458333333333335vw;
            border: none;
            font-weight: 600;
            font-size: 0.7291666666666666vw;
            cursor: pointer;
            transition: all ease 0.2s;
            &:hover {
              background: #af3219;
            }
          }
        }
      }
    }
    .footer_right {
      width: 70%;
      min-height: 30vh;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: start;
      flex-wrap: wrap;
      .footer_collums {
        width: 25%;
        margin-bottom: 2.6041666666666665vw;
        .title {
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 1.0416666666666667vw;
        }
        .topics {
          text-transform: uppercase;
          font-size: 12px;
          margin-bottom: 0.5208333333333334vw;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            color: #f2411f;
          }
        }
      }
      .contact {
        width: 75%;
        div {
          span {
            &:nth-child(1) {
              display: inline-block;
              width: 15.416666666666666vw !important;
            }
            &:nth-child(2) {
              img {
                padding-right: 10px;
              }
            }
          }
        }
      }
      .social {
        .icon_container {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          div {
            margin-right: 1.0416666666666667vw;
          }
        }
      }
    }
  }
  .sub_footer {
    width: calc(100% - 4vw);
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 2vw;
    border-top: 1px solid #c3c3c3;
    .rights {
      font-size: 0.5208333333333334vw;
    }

    .got_to_top {
      height: 3.125vw;
      width: 3.125vw;
      background: #f2411f;
      border-radius: 1.0416666666666667vw;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        background: #ac2d14;
      }
    }
    div {
      width: 33%;
    }
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .footer_wrapper {
    .Marquee-tag {
      .info_mar {
        font-size: 5vw;
        a {
          button {
            padding: 0 10vw;
            border-radius: 20vw;
            font-size: 4vw;
          }
        }
      }
    }

    .track {
      position: absolute;
      white-space: nowrap;
      will-change: transform;
      animation: marquee 32s linear infinite;
      font-size: 5vw;
      .content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .footer_container {
      width: calc(100% - 4vw);
      min-height: 50vh;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      background: #fff;
      border-radius: 1.5625vw 1.5625vw 0 0;
      padding: 8vw 8vw 0 8vw;

      .footer_left {
        width: 100%;
        min-height: 30vh;
        height: auto;
        margin-bottom: 5vh;
        .footer_headline {
          font-size: 6vw;
          width: 100%;
          margin-top: 3vw;
        }

        form {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          margin-top: 1.5625vw;
          input {
            height: 8.6041666666666665vw;
            margin-bottom: 3vw;
            border-radius: 0.5208333333333334vw;
            width: 100%;
            background: #dfdfdf;
            padding: 1.0416666666666667vw;
            border: 1px solid #c3c3c3;
          }

          .check_container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 1.0416666666666667vw;
            margin-bottom: 10vw;
            input {
              height: 5.0416666666666667vw;
              margin-bottom: 0;
              border-radius: 0;
              width: auto;
              background: #dfdfdf;
              padding: 0 !important;
              border: 1px solid #c3c3c3;
              margin-right: 0.5208333333333334vw;
            }
            font-size: 12px;

            span {
              color: #f2411f;
              margin-left: 0.2604166666666667vw;
              font-weight: 600;
            }
          }
          .form__sub {
            width: 100%;
            font-size: 10px;
            input[type="submit"] {
              padding: 0 7vw;
              height: 8.6458333333333335vw;
              border-radius: 8vw;
              border: none;
              font-weight: 600;
              font-size: 2.5vw;
              width: 100%;
            }
          }
        }
      }
      .footer_right {
        width: 100%;
        min-height: 30vh;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-wrap: wrap;
        // flex-direction: column;
        .footer_collums {
          width: 50%;
          min-height: 18vh;
          margin-bottom: 5vw;
          border-bottom: 1px solid #ddd;

          .title {
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 1.0416666666666667vw;
          }
          .topics {
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 0.5208333333333334vw;
            transition: all ease 0.3s;
            cursor: pointer;
            &:hover {
              color: #f2411f;
            }
          }
        }
        .contact {
          width: 100%;

          div {
            margin-bottom: 50px;
            span {
              &:nth-child(1) {
                display: inline-block;
                width: 100% !important;
                font-weight: 700;
              }
              &:nth-child(2) {
                display: inline-block;
                width: 100% !important;
                display: flex;
                align-items: flex-start;
                justify-content: start;
                flex-direction: row-reverse;
                margin-bottom: 30px;
                img {
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .social {
          .icon_container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            div {
              margin-right: 1.0416666666666667vw;
            }
          }
        }
      }
    }
    .sub_footer {
      width: calc(100% - 4vw);
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 8vw;
      border-top: 1px solid #c3c3c3;
      .rights {
        font-size: 2vw;
      }

      .got_to_top {
        height: 3.125vw;
        width: 3.125vw;
        background: #f2411f;
        border-radius: 1.0416666666666667vw;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          background: #ac2d14;
        }
      }
      div {
        width: 100%;
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}
