.info_wrapper {
  width: 100%;
  min-height: 20vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  padding: 7.5vh 0;
}
.info_wrapper .info_container {
  width: calc(100% - 10vw);
  min-height: 20vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info_wrapper .info_container .info_info_left {
  min-height: 20vh;
  height: auto;
  width: 50%;
}
.info_wrapper .info_container .info_info_left .info_headline {
  font-size: 2.0833333333vw;
  line-height: 2.6041666667vw;
  margin: 2.0833333333vw 0;
  font-weight: 500;
  padding-right: 2.6041666667vw;
}
.info_wrapper .info_container .info_info_left .info_headline span {
  color: #f2411f;
  font-weight: 700;
}
.info_wrapper .info_container .info_info_left .info_headline span:nth-child(1) {
  color: #fff;
}
.info_wrapper .info_container .info_info_left .info_subheadline {
  font-size: 1.0416666667vw;
  font-weight: 200;
  margin-bottom: 2.0833333333vw;
  padding-right: 2.6041666667vw;
}
.info_wrapper .info_container .info_info_right {
  min-height: 20vh;
  height: auto;
  width: 50%;
}
.info_wrapper .info_container .info_info_right img {
  width: 100%;
  padding: 8%;
}

@media (min-width: 0px) and (max-width: 768px) {
  .info_wrapper {
    padding: 2vh 0 7.5vh 0;
  }
  .info_wrapper .info_container {
    height: auto;
    flex-direction: column-reverse;
  }
  .info_wrapper .info_container .info_info_left {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .info_wrapper .info_container .info_info_left .info_headline {
    font-size: 5.0833333333vw;
    line-height: 5.6041666667vw;
    margin: 2.0833333333vw 0;
    padding-right: 0;
  }
  .info_wrapper .info_container .info_info_left .info_subheadline {
    font-size: 3.0416666667vw;
    padding-right: 0;
  }
  .info_wrapper .info_container .info_info_right {
    min-height: 20vh;
    height: auto;
    width: 50%;
  }
  .info_wrapper .info_container .info_info_right img {
    width: 110%;
    margin-bottom: 5vh;
    padding: 0;
  }
}/*# sourceMappingURL=style.css.map */