.footer_wrapper {
  width: 100%;
  min-height: 30vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #f2411f;
  border-radius: 1.5625vw 1.5625vw 0 0;
}
.footer_wrapper .Marquee {
  border-bottom: 1px solid rgba(34, 35, 48, 0.2);
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  color: #fff;
  font-weight: 200;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -o-box-align: center;
  align-items: center;
  overflow: hidden;
}
.footer_wrapper .Marquee-content {
  display: -ms-flexbox;
  display: box;
  display: flex;
  animation: marquee 40s linear infinite running;
}
.footer_wrapper .Marquee-content:hover {
  animation-play-state: paused;
}
.footer_wrapper .Marquee-tag {
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  margin: 0 50px;
  padding: 0.5em;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -o-box-align: center;
  align-items: center;
  -o-box-pack: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.footer_wrapper .Marquee-tag .info_mar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #222330;
  font-weight: 600;
  margin-right: 50px;
  width: 50%;
  font-size: 1.4vw;
}
.footer_wrapper .Marquee-tag .info_mar a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  color: #222330;
  cursor: pointer;
}
.footer_wrapper .Marquee-tag .info_mar a button {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 2vw;
  height: 5vh;
  background: #222330;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15625vw;
  border-radius: 3.6458333333vw;
  border: none;
  font-weight: 600;
  font-size: 0.7291666667vw;
  margin-right: 20px;
}
.footer_wrapper .Marquee-tag:hover {
  transform: scale(1.1);
  cursor: pointer;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
.footer_wrapper .footer_container {
  width: calc(100% - 4vw);
  min-height: 50vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  border-radius: 1.5625vw 1.5625vw 0 0;
  padding: 4vw;
}
.footer_wrapper .footer_container .footer_left {
  width: 30%;
  min-height: 30vh;
  height: auto;
}
.footer_wrapper .footer_container .footer_left .footer_headline {
  font-size: 1.1458333333vw;
  width: 80%;
  margin-top: 1.3020833333vw;
}
.footer_wrapper .footer_container .footer_left form {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.5625vw;
}
.footer_wrapper .footer_container .footer_left form input {
  height: 2.6041666667vw;
  margin-bottom: 1.0416666667vw;
  border-radius: 0.5208333333vw;
  width: 82%;
  background: #dfdfdf;
  padding: 1.0416666667vw;
  border: 1px solid #c3c3c3;
}
.footer_wrapper .footer_container .footer_left form .check_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  height: 1.0416666667vw;
  margin-bottom: 1.5625vw;
  font-size: 12px;
}
.footer_wrapper .footer_container .footer_left form .check_container input {
  height: 1.0416666667vw;
  margin-bottom: 0;
  border-radius: 0;
  width: auto;
  background: #dfdfdf;
  padding: 0 !important;
  border: 1px solid #c3c3c3;
  margin-right: 0.5208333333vw;
}
.footer_wrapper .footer_container .footer_left form .check_container span {
  color: #f2411f;
  margin-left: 0.2604166667vw;
  font-weight: 600;
}
.footer_wrapper .footer_container .footer_left form .form__sub {
  width: 83%;
  font-size: 10px;
}
.footer_wrapper .footer_container .footer_left form .form__sub input[type=submit] {
  height: 3.6458333333vw;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 2vw;
  background: #f2411f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15625vw;
  border-radius: 3.6458333333vw;
  border: none;
  font-weight: 600;
  font-size: 0.7291666667vw;
  cursor: pointer;
  transition: all ease 0.2s;
}
.footer_wrapper .footer_container .footer_left form .form__sub input[type=submit]:hover {
  background: #af3219;
}
.footer_wrapper .footer_container .footer_right {
  width: 70%;
  min-height: 30vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-wrap: wrap;
}
.footer_wrapper .footer_container .footer_right .footer_collums {
  width: 25%;
  margin-bottom: 2.6041666667vw;
}
.footer_wrapper .footer_container .footer_right .footer_collums .title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.0416666667vw;
}
.footer_wrapper .footer_container .footer_right .footer_collums .topics {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0.5208333333vw;
  transition: all ease 0.3s;
  cursor: pointer;
}
.footer_wrapper .footer_container .footer_right .footer_collums .topics:hover {
  color: #f2411f;
}
.footer_wrapper .footer_container .footer_right .contact {
  width: 75%;
}
.footer_wrapper .footer_container .footer_right .contact div span:nth-child(1) {
  display: inline-block;
  width: 15.4166666667vw !important;
}
.footer_wrapper .footer_container .footer_right .contact div span:nth-child(2) img {
  padding-right: 10px;
}
.footer_wrapper .footer_container .footer_right .social .icon_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer_wrapper .footer_container .footer_right .social .icon_container div {
  margin-right: 1.0416666667vw;
}
.footer_wrapper .sub_footer {
  width: calc(100% - 4vw);
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 2vw;
  border-top: 1px solid #c3c3c3;
}
.footer_wrapper .sub_footer .rights {
  font-size: 0.5208333333vw;
}
.footer_wrapper .sub_footer .got_to_top {
  height: 3.125vw;
  width: 3.125vw;
  background: #f2411f;
  border-radius: 1.0416666667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.2s;
}
.footer_wrapper .sub_footer .got_to_top:hover {
  background: #ac2d14;
}
.footer_wrapper .sub_footer div {
  width: 33%;
}

@media (min-width: 0px) and (max-width: 768px) {
  .footer_wrapper .Marquee-tag .info_mar {
    font-size: 5vw;
  }
  .footer_wrapper .Marquee-tag .info_mar a button {
    padding: 0 10vw;
    border-radius: 20vw;
    font-size: 4vw;
  }
  .footer_wrapper .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 32s linear infinite;
    font-size: 5vw;
  }
  .footer_wrapper .track .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .footer_wrapper .footer_container {
    width: calc(100% - 4vw);
    min-height: 50vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border-radius: 1.5625vw 1.5625vw 0 0;
    padding: 8vw 8vw 0 8vw;
  }
  .footer_wrapper .footer_container .footer_left {
    width: 100%;
    min-height: 30vh;
    height: auto;
    margin-bottom: 5vh;
  }
  .footer_wrapper .footer_container .footer_left .footer_headline {
    font-size: 6vw;
    width: 100%;
    margin-top: 3vw;
  }
  .footer_wrapper .footer_container .footer_left form {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.5625vw;
  }
  .footer_wrapper .footer_container .footer_left form input {
    height: 8.6041666667vw;
    margin-bottom: 3vw;
    border-radius: 0.5208333333vw;
    width: 100%;
    background: #dfdfdf;
    padding: 1.0416666667vw;
    border: 1px solid #c3c3c3;
  }
  .footer_wrapper .footer_container .footer_left form .check_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 1.0416666667vw;
    margin-bottom: 10vw;
    font-size: 12px;
  }
  .footer_wrapper .footer_container .footer_left form .check_container input {
    height: 5.0416666667vw;
    margin-bottom: 0;
    border-radius: 0;
    width: auto;
    background: #dfdfdf;
    padding: 0 !important;
    border: 1px solid #c3c3c3;
    margin-right: 0.5208333333vw;
  }
  .footer_wrapper .footer_container .footer_left form .check_container span {
    color: #f2411f;
    margin-left: 0.2604166667vw;
    font-weight: 600;
  }
  .footer_wrapper .footer_container .footer_left form .form__sub {
    width: 100%;
    font-size: 10px;
  }
  .footer_wrapper .footer_container .footer_left form .form__sub input[type=submit] {
    padding: 0 7vw;
    height: 8.6458333333vw;
    border-radius: 8vw;
    border: none;
    font-weight: 600;
    font-size: 2.5vw;
    width: 100%;
  }
  .footer_wrapper .footer_container .footer_right {
    width: 100%;
    min-height: 30vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    flex-wrap: wrap;
  }
  .footer_wrapper .footer_container .footer_right .footer_collums {
    width: 50%;
    min-height: 18vh;
    margin-bottom: 5vw;
    border-bottom: 1px solid #ddd;
  }
  .footer_wrapper .footer_container .footer_right .footer_collums .title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.0416666667vw;
  }
  .footer_wrapper .footer_container .footer_right .footer_collums .topics {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0.5208333333vw;
    transition: all ease 0.3s;
    cursor: pointer;
  }
  .footer_wrapper .footer_container .footer_right .footer_collums .topics:hover {
    color: #f2411f;
  }
  .footer_wrapper .footer_container .footer_right .contact {
    width: 100%;
  }
  .footer_wrapper .footer_container .footer_right .contact div {
    margin-bottom: 50px;
  }
  .footer_wrapper .footer_container .footer_right .contact div span:nth-child(1) {
    display: inline-block;
    width: 100% !important;
    font-weight: 700;
  }
  .footer_wrapper .footer_container .footer_right .contact div span:nth-child(2) {
    display: inline-block;
    width: 100% !important;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    flex-direction: row-reverse;
    margin-bottom: 30px;
  }
  .footer_wrapper .footer_container .footer_right .contact div span:nth-child(2) img {
    margin-left: 10px;
  }
  .footer_wrapper .footer_container .footer_right .social .icon_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer_wrapper .footer_container .footer_right .social .icon_container div {
    margin-right: 1.0416666667vw;
  }
  .footer_wrapper .sub_footer {
    width: calc(100% - 4vw);
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 8vw;
    border-top: 1px solid #c3c3c3;
  }
  .footer_wrapper .sub_footer .rights {
    font-size: 2vw;
  }
  .footer_wrapper .sub_footer .got_to_top {
    height: 3.125vw;
    width: 3.125vw;
    background: #f2411f;
    border-radius: 1.0416666667vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .footer_wrapper .sub_footer .got_to_top:hover {
    background: #ac2d14;
  }
  .footer_wrapper .sub_footer div {
    width: 100%;
  }
  .footer_wrapper .sub_footer div:nth-child(2) {
    display: none;
  }
  .footer_wrapper .sub_footer div:nth-child(3) {
    display: none;
  }
}/*# sourceMappingURL=style.css.map */