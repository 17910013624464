.menu_wrapper {
  width: 100%;
  height:100px;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  .menu_container {
    width: calc(100% - 10vw);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        position: relative;
        top: -1vh;
        width: 180px;
      }
      .nav {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2.208333333333333vw;
        height: 100%;
        div {
          font-size: 0.625vw;
          text-transform: uppercase;
          letter-spacing: 0.15625vw;
          margin-right: 1.5vw;
          color: #fff;
          transition: all ease 0.2s;
          cursor: pointer;
          &:hover {
            color: #f2411f;
          }
        }
        .navop {
          max-width: fit-content !important;
        }
      }
    }
    button {
      height: 2.6041666666666665vw;
      width: fit-content;
      padding: 0 2vw;
      background: #f2411f;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 0.15625vw;
      border-radius: 2.6041666666666665vw;
      border: none;
      font-weight: 600;
      font-size: 0.7291666666666666vw;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        background: #af3219;
      }
    }
  }
}

.active {
  background: #222330;
  height: 70px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .menu_wrapper {
    width: 100%;
    height: 18vw;
    .menu_container {
      .logo {
        img {
          width: 100px;
        }
        .nav {
          div {
            font-size: 2vw;
          }
        }
      }
      button {
        padding: 0 2vw;
        height: 5.6458333333333335vw;
        font-size: 2vw;
      }
    }
  }

  .active {
    background: #222330;
    height: 10vw;
  }
}
