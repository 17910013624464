.subscribe {
  height: 100vh;
  width: 100%;
  background: rgba(10, 9, 9, 0.445);
  position: fixed;
  z-index: 1000;
  top: 0;
  padding: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.subscribe__container {
  height: 80vh;
  width: 60vw;
  position: fixed;
  z-index: 2000;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #21222f;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;

  .image_subs {
    width: 40%;
    height: 100%;
    background: url(../../assets/img/news.jpg) #fea23d;
    background-position: center;
    background-size: cover;
    border-radius: 8px 0 0 8px;
  }
  .form_subscribe {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 20;
    padding: 2.6041666666666665vw;
    box-sizing: border-box;
    .close {
      height: 1.5625vw;
      height: 1.5625vw;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 1.5625vw;
      top: 40px;

      cursor: pointer;
      box-sizing: border-box;
      svg {
        opacity: 0.6;
      }
    }

    .subscribe_information {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin: 2.6041666666666665vw 0;
      box-sizing: border-box;
      width: 100%;

      .subscribe_headline {
        width: 100%;
        font-size: 2vw;
        line-height: 2vw;
        font-weight: 600;
        box-sizing: border-box;
        margin-bottom: 4vh;
      }
      .subscribe_subheadline {
        width: 100%;
        box-sizing: border-box;
      }
    }
    label {
      text-transform: uppercase;
      font-size: 0.5208333333333334vw;
      font-weight: 600;
      margin-bottom: 1.0416666666666667vw;
      box-sizing: border-box;
    }
    form {
      width: 100%;
      input {
        // height: 2.6041666666666665vw;
        margin-bottom: 1.0416666666666667vw;
        border-radius: 0.5208333333333334vw;
        width: 100%;
        background: #dfdfdf;
        padding: 1.0416666666666667vw;
        border: 1px solid #c3c3c3;
        &::placeholder {
          font-size: 0.7291666666666666vw;

          box-sizing: border-box;
        }
        &:focus {
          outline: none;
        }

        &:nth-child(1) {
          // display: none;
        }
        &:nth-child(2) {
          display: none;
        }
      }
      input[type="submit"] {
        background: #f2411f;
        margin-top: 1.0416666666666667vw;
        transition: all ease 0.3s;
        border-radius: 50px;
        color: #fff;
        font-size: 0.8333333333333334vw;
        // height: 2.6041666666666665vw !important;
        text-transform: uppercase;
        letter-spacing: 0.15625vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          background: #af3219;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .subscribe {
    height: 100vh;
    width: 100vw;
  }

  .subscribe__container {
    min-height: 80vh;
    height: auto;
    width: 100vw;
    padding: 8vw;

    .image_subs {
      display: none;
    }
    .form_subscribe {
      height: 100%;
      width: 100%;

      .subscribe_information {
        .subscribe_headline {
          font-size: 8vw;
          line-height: 8vw;
          margin-bottom: 4vh;
        }
        .subscribe_subheadline {
        }
      }
      label {
        font-size: 3vw;
        margin-bottom: 5vw;
        margin-top: 5vw;
      }
      form {
        width: 100%;
        input {
          margin-bottom: 5vw;
          border-radius: 0.5208333333333334vw;
          padding: 3vw;

          &::placeholder {
            font-size: 3vw;
            box-sizing: border-box;
          }
        }
        input[type="submit"] {
          margin-top: 5vw;
          font-size: 5vw;
          height: 50px !important;
        }
      }
    }
  }

  .subscribe__container {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
